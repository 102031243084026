<section class="section banner" id='banner'>
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div class="top">ciccio</div>
                <div>
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h2>{{"Banner.Name" | translate}}</h2>
                    <h3>{{"Banner.Subname" | translate}}</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "email")' href="mailto:roberto.trombetta@niceville.it" target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>

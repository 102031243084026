<section class="section services" id='services'>
  <div class="container">
      <div class="section-box">
          <div class="services-title top" data-aos="fade-up">
              <h3 class="section-title">
                  <span class='n-section-title'>02.</span> {{"Services.Title" | translate}}
              </h3>
          </div>
          <div class="row">
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                  <div class="services-description">
                      <p *ngFor='let text of "Services.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                      </p>
                  </div>
              </div>
              <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                  <div class="services-img-container">
                      <img (click)='analyticsService.sendAnalyticEvent("click_image", "services", "image")' class="rounded" width="400" height="300" src="assets/images/niceville/servizi.png" alt="Servizi">
                  </div>
              </div>

          </div>
      </div>
  </div>
</section>

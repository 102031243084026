<section class="section proyects" id='proyects'>
    <div class="container">
        <div class="section-box">
            <div class="about-title top" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>03.</span> {{"University.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "University.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="proyects-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "proyects", "image")' class="rounded" width="400" height="300" src="assets/images/niceville/collaborazioni-scientifiche.png" alt="Collaborazioni scientifiche">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
